<template>
  <!-- Dialogo de confirmação -->
  <div>
      <ConfirmDialog>
        <template #message>
          <span>
            Você deseja realmente alterar o vínculo <b>"{{ descricao }}"</b> para o item normalizado <b>"{{ normalizadoLabel }}"</b>?
          </span>
        </template>
      </ConfirmDialog>
  </div>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Nova Chave" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Apagar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                :disabled="!selectUnidadesChave || !selectUnidadesChave.length" />
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
          </template>
        </Toolbar>

        <!-- Tabela de Unidades -->
        <DataTable ref="dt" :value="tiposTensoes" v-model:selection="selectUnidadesChave" dataKey="id" :paginator="true"
          :rows="50" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciar Itens</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
            <template #body="slotProps">
              <span class="p-column-title">Id</span>
              {{ slotProps.data.id }}
            </template>
          </Column>

          <Column field="descricao" header="Item fatura Normalizado" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <span class="p-column-title">Item fatura normalizado</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>

          <Column field="descricao" header="Descrições vinculadas" :sortable="true" headerStyle="width:30%; min-width:10rem;">
            <template #body="slotProps">
              <Button class="p-button p-button-primary mr-2" @click="palavrasVinculadas(slotProps.data)">Exibir itens</Button>

            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">

              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                @click="apagarChaveModal(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <!-- Modal Adição/Edição de Tipo de tensão -->
        <Dialog v-model:visible="chaveDialog" :style="{ width: '450px' }" header="Editar Palavra Chave" :modal="true"
          class="p-fluid">
          <div class="field">
            <label for="name">Descrição</label>
            <InputText id="bome" v-model.trim="chave.descricao" required="true" autofocus />
            <small class="p-invalid" v-if="submitted && !chave.descricao">Descrição é obrigatória.</small>
          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm" @click="hideDialog" />
            <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarChave" />
          </template>
        </Dialog>

        <!-- Modal deletar Unidade Tipo de tensão -->
        <Dialog v-model:visible="deletechaveDialog" :style="{ width: '450px' }" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="chave">Certeza que deseja apagar este item: <b>{{ chave.descricao }}</b>?</span>
          </div>
          <template #footer class="align-items-center">
            <Button label="Não" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click=" deletechaveDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-danger p-button-sm" @click="apagarChave" />
          </template>
        </Dialog>

        <!-- Modal deletar vários selecionados -->
        <Dialog v-model:visible="deleteProductsDialog" :style="{ width: '450px' }" header="" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="chave">Apagar Tipo de tensão?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
          </template>
        </Dialog>

        <!-- Modal Adição/Edição de Tipo de tensão -->
        <Dialog v-model:visible="palavrasDialog" :style="{ width: '800px' }" header="Editar Palavras Vinculadas"
          :modal="true" class="p-fluid">
          <DataTable ref="dt" :value="palavras" v-model:selection="selectUnidadesChave" dataKey="id" :paginator="true"
            :rows="20" :filters="pfilters"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25]"
            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
            responsiveLayout="scroll">
            <template #header>
              <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">

                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="pfilters['global'].value" placeholder="Buscar" />
                </span>
              </div>
            </template>

            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="id" header="ID" :sortable="true" headerStyle="width:14%; min-width:5rem;">
              <template #body="slotProps">
                <span class="p-column-title">Id</span>
                {{ slotProps.data.id }}
              </template>
            </Column>

            <Column field="descricao" header="Palavra sanatizada e normalizado" :sortable="true" headerStyle="width:50%; min-width:10rem;">
              <template #body="slotProps">
                <span  class="p-column-title">Text Mining</span>
                {{ slotProps.data.descricao }}
              </template>
            </Column>

            <!-- <Column field="descricao" header="Original" :sortable="true" headerStyle="width:30%; min-width:10rem;">
              <template #body="slotProps">
                <span class="p-column-title">Original</span>
                {{ slotProps.data.original }}
              </template>
            </Column> -->

            <Column headerStyle="min-width:10rem;">
              <template #body="slotProps">

                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                  @click="editarVinculo(slotProps.data)" />
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                  @click="removerVinculo(slotProps.data)" />
              </template>
            </Column>
          </DataTable>

          <template #footer>

          </template>
        </Dialog>

        <Dialog v-model:visible="editarVinculoModal" :style="{ width: '450px' }" header="Mudar vínculo" :modal="true">
          <div class="flex align-items-center justify-content-start">
            <Dropdown id="id_tipo" filter required="true" v-model="id_normalizado" :options="normalizados"
              optionLabel="label" optionValue="value" placeholder="Selecione o item para víncular " @change="vincularPalavra()">
            </Dropdown>
          </div>
        </Dialog>

      </div>
    </div>
  </div>
</template>

<script>

import { FilterMatchMode } from "primevue/api";
import ConfirmDialog  from "primevue/confirmdialog/ConfirmDialog.vue";


export default {
  data() {
    return {
      components: {
        ConfirmDialog: ConfirmDialog,
      },
      tiposTensoes: [],
      palavras: [],
      chave: {},
      selectUnidadesChave: null,
      selectedProducts: null,
      chaveDialog: false,
      tipos_tensao: [],
      deletechaveDialog: false,
      filters: {},
      pfilters: {},
      submitted: false,
      deleteProductsDialog: false,
      palavrasDialog: false,
      editarVinculoModal: false,
      normalizados: {},
      id_normalizado: "",

    };
  },
  // productService: null,
  created() {
    this.initFilters();
  },
  mounted() {
    this.ListarChaves();
    // this.listarTiposUnidadesTensao(); 
  },


  methods: {


    /**
     * vincularPalavra atribui atualiza um item viculado a outro
     * 
     * - Processa:
     *   -Pede a confirmação para vincular o item
     *    - Ao confirmar: Faz o envio para o backend salvar o item para no novo item normalizado a ser vínculado
     *    - Ao negar: Não faz o vinculo e notifica o usuário.
     */
    vincularPalavra() {

      this.descricao = this.palavras.find(
      (item) => item.id === this.chave.id )?.descricao || "Valor não encontrado";

      this.normalizadoLabel = this.normalizados.find(
      (item) => item.value === this.id_normalizado )?.label || "Valor não encontrado";

      this.$confirm.require({
        header: `Vincular a descrição ao item: ${this.normalizadoLabel} ?`,
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.submitted = true;

          let data = {
            id_chave: this.chave.id,
            id_normalizado: this.id_normalizado
          }

         // Atualizar um tipos-tensão
        this.axios
          .post("/dicionario/chaves/alterar", data)
          .then(() => {
            this.chaveDialog = false;
            this.palavrasVinculadas(this.chave);
            
            this.$toast.add({
                severity: "success",
                summary: "Sucesso!",
                detail: "Vínculo alterado com sucesso!",
                life: 3000,
              });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
        },
        reject: () => {
          // Caso o usuário cancele
          this.$toast.add({
            severity: "info",
            summary: "Cancelado",
            detail: "Ação cancelada pelo usuário.",
            life: 3000,
          });
        },
      });
      this.editarVinculoModal = false;
      this.chave = {};
    },

    // listar unidades do end-point
    ListarChaves() {
      this.axios
        .post("/dicionario/listar-chaves")
        .then((response) => {
          this.tiposTensoes = response.data.dados;

          let options = [];
          let desc = {};
          response.data.dados.map((el) => {

            let option = { value: el["id"], label: el["descricao"] };
            desc[el["id"]] = el["descricao"];
            options.push(option);

          });
          this.normalizados = options;
        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    openNew() {
      this.chave = {};
      this.submitted = false;
      this.chaveDialog = true;
    },

    editProduct(chave) {
      this.chaveDialog = true;
      this.chave = { ...chave };
    },


    editarVinculo(chave) {
      this.editarVinculoModal = true;
      this.chave = { ...chave };
    },


    palavrasVinculadas(chave) {
      this.palavrasDialog = true;
      this.chave = { ...chave };

      let dados = {
        id: this.chave.id
      }

      this.axios
        .post("/dicionario/listar-palavras", dados)
        .then((response) => {
          this.palavras = response.data.dados;
          console.log(response.data.dados);

        })
        .catch(() => {
          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },


    hideDialog() {
      this.chaveDialog = false;
      this.submitted = false;
    },




    // Cria / Editar  -> tipos de tensão
    salvarChave() {
      this.submitted = true;

      if (this.chave.id) {
        // Atualizar um tipos-tensão
        this.axios
          .post("/dicionario/chaves/salvar", this.chave)
          .then(() => {

            this.chaveDialog = false;
            this.chave = {};
            this.ListarChaves();
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      } else {
        // Cadastrar novo tipos-tensão
        this.axios
          .post("/dicionario/chaves/salvar", this.chave)
          .then(() => {
            this.chaveDialog = false;
            this.chave = {};
            this.ListarChaves();
          })
          .catch(() => {
            self.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      }
    },


    //Apaga um tipo de tensão
    apagarChave() {
      this.axios
        .post("/dicionario/chaves/apagar", this.chave)
        .then(() => {

          this.deletechaveDialog = false;
          this.chave = {};
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Usuário apagado com sucesso!",
            life: 3000,
          });
          this.ListarChaves();
        })
        .catch(() => {

          self.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },

    //Apaga o Modal que abre ao selecionar que deseja apagar uma fatura
    apagarChaveModal(chave) {
      this.chave = chave;
      this.deletechaveDialog = true;
    },

    // botao de excluir selecionados
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },


    // Faz o delete de vários items selecionados no datatable
    deleteSelectedProducts() {
      this.tiposTensoes = this.tiposTensoes.filter(
        (val) => !this.selectUnidadesChave.includes(val)
      );

      let obj = this.selectUnidadesChave;
      Object.keys(obj).forEach((key) => {
        this.axios
          .post("/unidades/dados/tipos-tensao/apagar", obj[key])
          .then(() => {
            this.listarUsuario();
            this.deletechaveDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Tipo tensão apagado com sucesso!",
              life: 3000,
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
          });
      });

      this.deleteProductsDialog = false;
      this.selectUnidadesChave = null;
      this.$toast.add({
        severity: "success",
        summary: "Sucesso",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    /*
 
    */

    exportCSV() {
      this.$refs.dt.exportCSV();
    },


    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };

      this.pfilters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },

  },
};


</script>
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>